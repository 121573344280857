import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import Joi from "joi"
import { noop } from "lodash-es"
import React, { FC, useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { useAppSelector } from "src/app/hooks"
import { useGetSstLockersQuery } from "src/features/partners/partnersApi"
import {
  selectSstLockers,
  SstLockerThing,
} from "src/features/partners/sstThingsSlice"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
  useUpdateThingsMutation,
} from "../features/things/thingsApi"
import FormFields, { FieldsGeneratorFn, FormField } from "./FormFields"
import { Sl2FormContainer } from "./Sl2FormContainer"

const defaultLocker: Partial<Locker> = {
  thingTypeMrn: LockerThingTypeMrn,
}

const schema = Joi.object({
  name: Joi.string().allow("").max(100).optional(),
  nameEn: Joi.string().allow("").max(100).optional(),
  nameFr: Joi.string().allow("").max(100).optional(),
  description: Joi.string().allow("").max(10000).optional(),
  address1: Joi.string().allow("").max(1000).optional(),
  address2: Joi.string().allow("").max(1000).optional(),
  city: Joi.string().allow("").max(100).optional(),
  province: Joi.string().allow("").max(100).optional(),
  country: Joi.string().allow("").max(100).optional(),
  postalCode: Joi.string().allow("").max(100).optional(),
  latitude: Joi.string().allow("").max(100).optional(),
  longitude: Joi.string().allow("").max(100).optional(),
  lockerLocation: Joi.string().allow("").max(1000).optional(),
  lockerLocationEn: Joi.string().allow("").max(1000).optional(),
  lockerLocationFr: Joi.string().allow("").max(1000).optional(),
  displayOrder: Joi.number().allow("").optional(),
  isVirtual: Joi.boolean().optional(),
  tenantId: Joi.string().allow("").optional(),
  sstLockerId: Joi.string().allow("").optional(),
  sstLocker: Joi.object().optional(),
})

const fieldsGeneralFn: FieldsGeneratorFn = (action) => {
  const fields = [
    {
      name: "name",
      fieldType: "text",
      label: t("name"),
      required: true,
      size: "small",
      fullWidth: true,
      // gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "description",
      fieldType: "text",
      label: t("description"),
      required: false,
      size: "small",
      fullWidth: true,
      InputProps: {
        multiline: true,
        rows: 3,
      },
    },
    // add other fields: address1, address2, city, province, country, postalCode, latitude, longitude, lockerLocation, displayOrder
    {
      name: "address1",
      fieldType: "text",
      label: t("address1"),
      required: false,
      size: "small",
      fullWidth: true,
    },
    {
      name: "address2",
      fieldType: "text",
      label: t("address2"),
      required: false,
      size: "small",
      fullWidth: true,
    },
    {
      name: "city",
      fieldType: "text",
      label: t("city"),
      required: false,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "province",
      fieldType: "text",
      label: t("province"),
      required: false,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "country",
      fieldType: "text",
      label: t("country"),
      required: false,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "postalCode",
      fieldType: "text",
      label: t("postalCode"),
      required: false,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "latitude",
      fieldType: "text",
      label: t("latitude"),
      required: false,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "longitude",
      fieldType: "text",
      label: t("longitude"),
      required: false,
      size: "small",
      fullWidth: true,
      gridProps: { xs: 12, sm: 6 },
    },
    {
      name: "lockerLocation",
      fieldType: "text",
      label: t("lockerLocation"),
      required: false,
      size: "small",
      fullWidth: true,
    },
    {
      name: "displayOrder",
      fieldType: "number",
      type: "number",
      label: t("displayOrder"),
      required: false,
      size: "small",
      fullWidth: true,
    },
  ]
  if (action === "create") {
    return [
      {
        name: "isVirtual",
        fieldType: "bool",
        label: "virtual",
        required: false,
        size: "small",
        fullWidth: true,
      },
      ...fields,
    ] as FormField[]
  }
  return fields as FormField[]
}

const fieldsNotifFn: FieldsGeneratorFn = (action) => {
  const fields = [
    {
      name: "nameEn",
      fieldType: "text",
      label: t("nameEn"),
      required: false,
      size: "small",
      fullWidth: true,
    },
    {
      name: "nameFr",
      fieldType: "text",
      label: t("nameFr"),
      required: false,
      size: "small",
      fullWidth: true,
    },
    {
      name: "lockerLocationEn",
      fieldType: "text",
      label: t("lockerLocationEn"),
      required: false,
      size: "small",
      fullWidth: true,
    },
    {
      name: "lockerLocationFr",
      fieldType: "text",
      label: t("lockerLocationFr"),
      required: false,
      size: "small",
      fullWidth: true,
    },
  ] as FormField[]
  return fields
}
export const SaLockerForm: FC<{
  locker?: Locker
  isCreating?: boolean
  onFinish?: (isSuccess: boolean) => void
  onCancel?: () => void
}> = ({
  locker = defaultLocker,
  isCreating = false,
  onFinish = noop,
  onCancel = noop,
}) => {
  // snack bar usestate
  const [snackbarData, setSnackbarData] = React.useState({
    severity: "success" as "success" | "error" | "info" | "warning",
    message: "",
  })

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()

  // create
  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const { isLoading, refetch } = useGetSstLockersQuery({ params: {} })

  const sstLockers = useAppSelector(selectSstLockers) as SstLockerThing[]

  const navigate = useNavigate()

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setConfirmDialogOpen(false)
        const mutateFn = isCreating ? createThings : updateThings
        const thing = {
          ...defaultLocker,
          ...data,
          id: locker.id,

          thingTypeMrn: LockerThingTypeMrn,
          tenantId: isCreating ? data.tenantId : locker?.tenantId,
        }
        if (isCreating) {
          thing.lockerProvider = "sst"
          thing.lockerType = "sst.pharma"
        }
        if (data.sstLockerId) {
          thing.sstLocker = sstLockers.find((l) => l._id === data.sstLockerId)
        }
        const res = await mutateFn({
          body: { things: [thing] },
          params: { tenantId: isCreating ? data.tenantId : locker?.tenantId },
        }).unwrap()

        setSnackbarData({
          severity: "success",
          message: isCreating
            ? "Locker created successfully"
            : "Locker updated successfully",
        })
        onFinish(true)
        if (isCreating) {
          navigate(`/sa/lockers/${res.things[0].id}`)
        }
      } catch (error: any) {
        console.error(error)
        setSnackbarData({
          severity: "error",
          message: error?.message || "An error occurred",
        })
        onFinish(false)
      }
    },
    [
      createThings,
      isCreating,
      locker.id,
      locker?.tenantId,
      navigate,
      onFinish,
      sstLockers,
      updateThings,
    ],
  )

  const { data } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetTenants,
      "ap.scanIndexForward": false,
      "ap.tenantOnly": true,
      "ap.limit": 1000,
      "ap.nextToken": undefined,
    },
  })

  const tenantOptions = useMemo(() => {
    return (data?.things || []).map((tenant) => ({
      id: tenant.id,
      label: (
        <Stack
          direction="column"
          spacing={1}
          sx={{
            my: 1,
          }}
        >
          <Stack direction="row" spacing={6} alignItems="center">
            <Typography variant="body1" fontWeight="bold">
              {tenant.name}
            </Typography>
            {tenant.mblsProviderId && (
              <Chip
                label={`${t("mblsProviderId")} ${tenant.mblsProviderId}`}
                size="small"
                color="primary"
                variant="outlined"
              />
            )}
          </Stack>
          <Typography variant="caption">{tenant.id}</Typography>
        </Stack>
      ),
    }))
  }, [data?.things])

  const sstLockerOptions = useMemo(() => {
    return sstLockers.map((sstLocker) => ({
      id: sstLocker._id,
      label: (
        <Stack
          direction="column"
          spacing={1}
          sx={{
            my: 1,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            spacing={6}
            alignItems="center"
            justifyContent="space-between"
            // width={"100%"}
          >
            <Box>
              <Typography variant="body1" fontWeight="bolds">
                {`${sstLocker.description || "No description"} - ${
                  sstLocker.client_name
                }`}
              </Typography>
            </Box>
            <Box></Box>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption">
              {`${sstLocker.doors?.length} ${t("doors")}`}
            </Typography>
            <Typography variant="caption">-</Typography>
            <Typography variant="caption">
              {`${sstLocker.locker_grid ? "Grid" : "No grid"}`}
            </Typography>
            <Typography variant="caption">-</Typography>
            <Typography variant="caption">
              {`${sstLocker.payment ? "Payment" : "No payment"}`}
            </Typography>
            <Typography variant="caption">-</Typography>
            <Typography variant="caption">{`ID ${sstLocker._id}`}</Typography>
            <Typography variant="caption">-</Typography>
            <Typography variant="caption">{`Pin ${sstLocker.pin_number}`}</Typography>
          </Stack>
          <Typography variant="caption">{`📍 ${sstLocker.address_1} ${sstLocker.address_2} ${sstLocker.city} ${sstLocker.province} ${sstLocker.country}`}</Typography>
        </Stack>
      ),
    }))
  }, [sstLockers])

  const [isVirtualLocal, setIsVirtualLocal] = React.useState(locker.isVirtual)

  const { fieldsGeneral, defaultValues, fieldsNotif } = useMemo(() => {
    const action = isCreating ? "create" : "update"

    let fieldsGeneral = fieldsGeneralFn(action)
    let fieldsNotif = fieldsNotifFn(action)

    // pick values from locker based on fields

    const defaultValues = {} as Record<string, any>

    ;[...fieldsGeneral, ...fieldsNotif].forEach(({ fieldType, name }) => {
      if (fieldType === "bool") {
        // @ts-ignore
        defaultValues[name] = locker[name] || false
        return
      }
      // @ts-ignore
      defaultValues[name] = (locker[name] as any) || ""
    })

    if (isCreating) {
      const noEmptyOptions = [{ id: "", label: "No options" }]
      fieldsGeneral = [
        {
          name: "tenantId",
          fieldType: "select",
          options: tenantOptions.length ? tenantOptions : noEmptyOptions,
          label: t("tenant"),
          placeholder: t("selectUser"),
          required: true,
          size: "small",
          fullWidth: true,
          disabled: false,
          valueKey: "id",
        } as FormField,
        ...fieldsGeneral,
      ]
      const [tenantField, isVirtualField, ...rest] = fieldsGeneral
      if (!isVirtualLocal) {
        fieldsGeneral = [
          tenantField,
          isVirtualField,
          {
            name: "sstLockerId",
            fieldType: "select",
            options: sstLockerOptions.length
              ? sstLockerOptions
              : noEmptyOptions,
            label: t("sstLocker"),
            placeholder: t("selectUser"),
            required: true,
            size: "small",
            fullWidth: true,
            disabled: false,
            valueKey: "id",
          } as FormField,

          ...rest,
        ]
      }
    }

    return {
      fieldsGeneral,
      fieldsNotif,
      defaultValues,
    }
  }, [isCreating, isVirtualLocal, locker, sstLockerOptions, tenantOptions])

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false)

  return (
    <Stack direction="column" spacing={2}>
      {isCreating && (
        <Typography variant="h6" noWrap gutterBottom>
          {t("newLocker")}
        </Typography>
      )}
      <Divider sx={{ mb: 5 }} />
      <Sl2FormContainer
        joiSchema={schema}
        onSuccess={onSubmit}
        defaultValues={defaultValues}
      >
        <Stack direction="column" spacing={3}>
          <FormFields
            fields={fieldsGeneral}
            onWatch={(value, { name }) => {
              if (name === "isVirtual") {
                setIsVirtualLocal(value?.isVirtual)
              }
            }}
          />

          <Typography variant="body2" fontWeight="bold">
            {t("i18nFields")}
          </Typography>
          <Alert severity="info" variant="standard">
            {t("i18nFieldsDescription")}
          </Alert>
          <Alert severity="warning" variant="standard">
            {t("i18nFieldsWarning")}
          </Alert>
          <FormFields fields={fieldsNotif} />

          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 3 }}
            // alight items to right
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              size="small"
              onClick={onCancel}
              sx={{
                textTransform: "none",
                mt: 4,
              }}
              disabled={isUpdatingThings || isCreatingThings}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                textTransform: "none",
                mt: 4,
              }}
              disabled={isUpdatingThings || isCreatingThings}
            >
              {t(isCreating ? "create" : "save")}
            </Button>
          </Stack>
        </Stack>
        {/* <Dialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
        >
          <DialogTitle>{t("confirmSaveLocker")}</DialogTitle>
          <Divider />
          <DialogContent
            sx={{
              minWidth: "500px",
              minHeight: "100px",
            }}
          >
            <DialogContentText>
              {t("confirmSaveLockerDescription")}
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Sl2Btn onClick={() => setConfirmDialogOpen(false)} color="primary">
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn type="submit" color="primary" variant="contained">
              {t("save")}
            </Sl2Btn>
          </DialogActions>
        </Dialog> */}
      </Sl2FormContainer>
      <Snackbar
        open={snackbarData.message !== ""}
        onClose={() =>
          setSnackbarData({
            severity: "success",
            message: "",
          })
        }
        autoHideDuration={5000}
        message={snackbarData?.message}
      >
        <Alert severity={snackbarData.severity} variant="filled">
          {snackbarData?.message}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

export default SaLockerForm
