import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import {
  Box,
  CircularProgress,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"

import PersonSearchIcon from "@mui/icons-material/PersonSearch"
import { usePrevious } from "@uidotdev/usehooks"
import { TFunction } from "i18next"
import Joi from "joi"
import { isEmpty, isUndefined, noop, omit, throttle } from "lodash"
import { pick } from "lodash-es"
import React, { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Configuration } from "shared/configuration"
import { ConfigurationThingTypeMrn } from "shared/configurationData"
import { Locker } from "shared/locker"
import {
  formatValueAmount,
  ReservationLanguageList,
  ReservationThingTypeMrn,
} from "shared/reservationData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { Thing } from "shared/thing"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { RootState } from "src/app/store"
import { selectMe, selectTenantId } from "src/features/me/meSlice"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
} from "src/features/things/thingsApi"
import {
  selectMblsReservation,
  selectMblsReservationClient,
  selectReservationFormDefaultValues,
  selectSelectedClient,
  selectThingsByThingTypeMrnFilter,
  selectUserInputEmail,
  selectUserInputPhone,
  thingsActions,
} from "src/features/things/thingsSlice"
import { useCurrentTenant } from "src/hooks/useCurrentTenant"
import FormFields, { FormField } from "./FormFields"
import { OsReservationSelectedClient } from "./OsReservationSelectedClient"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

const reservationCreateSchema = (t: TFunction) =>
  Joi.object({
    clientId: Joi.string().required(),
    firstName: Joi.string().optional().messages({
      "any.required": "Please provide a first name",
    }),
    lastName: Joi.string().optional().messages({
      "any.required": "Please provide a last name",
    }),
    // email and phone validation are done in OsReservationSelectedClient component
    // email: Joi.string()
    //   .email({
    //     tlds: { allow: false },
    //   })
    //   .optional()
    //   .empty("")
    //   .messages({
    //     "any.required": "Please provide an email or phone number",
    //   }),
    // phone: Joi.string()
    //   .optional()
    //   // 10 digits, no spaces, no special characters
    //   .pattern(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
    //   .empty("")

    //   .messages({
    //     "any.required": "Please provide an email or phone number",
    //     "string.pattern.base": "Please provide a valid phone",
    //   }),
    notes: Joi.string().optional().empty(""),
    thingTypeMrn: Joi.string().required(),
    amountToCollect: Joi.number().optional(),
    expiresInDays: Joi.number().integer().positive().required().messages({
      "any.required": "Please provide an expiration date",
      "number.positive": "Please provide a positive number",
    }),
    lockerId: Joi.string().required(),
    doorId: Joi.string().required(),
    tenantId: Joi.string().required(),
    tenantUserId: Joi.string().required(),
    language: Joi.string()
      .required()
      .valid(...ReservationLanguageList),
    displayLanguage: Joi.string().optional(),
    employeeCode: Joi.string()
      .max(100)
      .required()
      .messages({
        "any.required": t("pleaseProvideEmployeeCode"),
      }),
  }).unknown(true)
// .or("email", "phone")
// .messages({
//   "object.missing": "Please provide an email or a phone number",
// })

export type OsLockerReservationFormProps = {
  locker: Locker
  doorId: string
  onClose?: () => void
  onSuccess?: ({ things, ids }: { things: Thing[]; ids: string[] }) => void
  onFail?: () => void
  displayNewClientCheckbox?: boolean
}

export const OsLockerReservationForm: FC<OsLockerReservationFormProps> = ({
  locker,
  doorId,
  onClose = noop,
  onSuccess = noop,
  onFail = noop,
  displayNewClientCheckbox = false,
}) => {
  const { t } = useTranslation()

  const tenantId = useAppSelector(selectTenantId)

  const [isProcessing, setIsProcessing] = React.useState(false)

  const [createThings] = useCreateThingsMutation()

  const { tenant } = useCurrentTenant()

  const me = useAppSelector(selectMe)

  const dispatch = useAppDispatch()

  const mblsReservationClient = useAppSelector(selectMblsReservationClient)
  const mblsReservation = useAppSelector(selectMblsReservation)

  const userInputEmail = useAppSelector(selectUserInputEmail)

  const userInputPhone = useAppSelector(selectUserInputPhone)

  const [formErrors, _setFormErrors] = React.useState<any>({})

  const setFormErrors = (errors: any) => {
    console.log("errors", errors)
    _setFormErrors(errors)
  }

  const [generalError, setGeneralError] = React.useState<any>("")

  const [selectedClientErrors, setSelectedClientErrors] = React.useState<any>(
    {},
  )

  const onSubmit = useCallback(
    async (data: any) => {
      setFormErrors({})
      setGeneralError("")
      try {
        setIsProcessing(true)

        const reservation = {
          ...omit(data, ["displayLanguage"]),
          email: userInputEmail,
          phone: userInputPhone,
        } as Record<string, any>

        // parse amoutToCollect to number if exists
        if (!isUndefined(reservation.amountToCollect)) {
          reservation.amountToCollect = parseFloat(reservation.amountToCollect)
        }

        if (mblsReservation?.orderThirdPartyID) {
          reservation.externalOrderId = mblsReservation.orderThirdPartyID
        }

        const res = await createThings({
          body: { things: [reservation] },
          params: { tenantId: tenant?.id },
        }).unwrap()

        onSuccess(res)

        setIsProcessing(false)

        dispatch(thingsActions.clearClientSelectionDefaultValues())

        const reservationId = res.things[0]?.id

        if (reservationId && locker?.id) {
          dispatch(
            thingsActions.setDoorReservationIds({
              doorId,
              reservationId,
              lockerId: locker?.id,
            }),
          )
        } else {
          onClose()
        }
      } catch (e: any) {
        console.error("e", e)
        if (e?.data?.cause === "ExternalOrderId already exists") {
          setGeneralError(
            t("externalOrderIdAlreadyExists", {
              id: mblsReservation.orderThirdPartyID,
            }),
          )
        }
        onFail()

        setFormErrors(e?.data?.cause?.details || { "": { message: e.message } })
        // detect email and phone errors and set them to selectedClientErrors
        const emailError = e?.data?.cause?.details?.find(
          (d: any) => d.path[0] === "email",
        )
        const phoneError = e?.data?.cause?.details?.find(
          (d: any) => d.path[0] === "phone",
        )
        setSelectedClientErrors({
          ...(emailError && { email: emailError }),
          ...(phoneError && { phone: phoneError }),
        })
        setIsProcessing(false)

        console.error(e)
      }
    },
    [
      createThings,
      dispatch,
      doorId,
      locker?.id,
      mblsReservation.orderThirdPartyID,
      onClose,
      onFail,
      onSuccess,
      t,
      tenant?.id,
      userInputEmail,
      userInputPhone,
    ],
  )

  const onValueChangeforSearch = useCallback(
    throttle(
      (value: any) => {
        const recipientValues = pick(value, [
          "firstName",
          "lastName",
          "email",
          "phone",
        ])
        dispatch(thingsActions.setClientSelectionSearchValues(recipientValues))
      },
      1000,
      { trailing: true },
    ),
    [],
  )

  useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetConfigurationsByTenant,
        "ap.tenantId": tenantId,
        "ap.refNames[0]": "configReservationExpirationDays",
        "ap.refNames[1]": "configPreferredLanguage",
        tenantId,
      },
    },
    {
      skip: !tenantId || !locker?.id,
    },
  )

  const configReservationExpirationDaysFilter = useCallback(
    (thing: Configuration) =>
      thing.tenantId === tenantId &&
      thing.refName === "configReservationExpirationDays",
    [tenantId],
  )

  const expireInDaysConfig = useAppSelector((s: RootState) =>
    selectThingsByThingTypeMrnFilter(
      s,
      ConfigurationThingTypeMrn,
      configReservationExpirationDaysFilter,
    ),
  )[0] as unknown as Configuration

  const configPreferredLanguageFilter = useCallback(
    (thing: Configuration) =>
      thing.tenantId === tenantId &&
      thing.refName === "configPreferredLanguage",
    [tenantId],
  )

  const configPreferredLanguage = useAppSelector((s: RootState) =>
    selectThingsByThingTypeMrnFilter(
      s,
      ConfigurationThingTypeMrn,
      configPreferredLanguageFilter,
    ),
  )[0] as unknown as Configuration

  const selectedClient = useAppSelector(selectSelectedClient)

  // detect selectedClient change
  const prevSelectedClient = usePrevious(selectedClient)
  useEffect(() => {
    if (prevSelectedClient?.id !== selectedClient?.id || !selectedClient?.id) {
      setSelectedClientErrors({})
    }
  }, [prevSelectedClient, selectedClient])

  useEffect(() => {
    const rawLang =
      selectedClient?.language || configPreferredLanguage?.sVal || "en"
    dispatch(
      thingsActions.updateClientSelectionDefaultValues({
        thingTypeMrn: ReservationThingTypeMrn,
        tenantId: tenant?.id,
        lockerId: locker?.id,
        doorId,
        tenantUserId: me?.tu?.id,
        expiresInDays: expireInDaysConfig?.nVal || 3,
        language: rawLang,
        displayLanguage: t(rawLang),
      }),
    )
  }, [
    configPreferredLanguage?.sVal,
    dispatch,
    doorId,
    expireInDaysConfig?.nVal,
    locker?.id,
    me?.tu?.id,
    selectedClient?.language,
    t,
    tenant?.id,
  ])

  const defaultValues = useAppSelector(selectReservationFormDefaultValues)

  const formFields = useMemo(() => {
    const recipentFields = [
      {
        name: "lastName",
        fieldType: "text",
        label: t("lastName"),
        type: "text",
        required: false,
        fullWidth: true,
        size: "small",
        autoFocus: !selectedClient?.id,
      },
      {
        name: "firstName",
        fieldType: "text",
        label: t("firstName"),
        required: false,
        fullWidth: true,
        size: "small",
      },
      {
        name: "email",
        fieldType: "text",
        label: t("email"),
        type: "email",
        required: false,
        fullWidth: true,
        size: "small",
      },
      {
        name: "phone",
        fieldType: "text",
        label: t("phone"),
        type: "tel",
        required: false,
        fullWidth: true,
        size: "small",
      },
    ] as FormField[]
    const amountToCollectField = {
      name: "amountToCollect",
      fieldType: "number",
      label: t("amountToCollect"),
      type: "number",
      required: false,
      fullWidth: true,
      size: "small",
      autoFocus: selectedClient?.id,
      InputProps: {
        endAdornment: "CAD",
      },
      inputProps: {
        // allow 0
        min: 0.0,
        step: 0.01,
      },
      onKeyDown: (e: any) => {
        // when 'Enter' key is pressed, blur the input field
        if (e.key === "Enter") {
          e.target.blur()
          // focus stackRef
          stackRef.current?.focus()
        }
        // pevent ArrowDown key and ArrowUp key
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          e.preventDefault()
        }
      },
      onChange: (s: string) => {
        // add 2 decimal places first, then add commas
        // const value = s.replace(/,/g, "")
        // const floatValue = parseFloat(value)
        // if (isNaN(floatValue)) return
        return formatValueAmount(s)
      },
      // onBlur: (s: string) => {
      //   return parseFloat(s)
      // },
      // transformValue: (value: any) => {
      //   // allow 2 decimal places
      //   // return parseFloat(value).toFixed(2)
      //   return formatValueAmount(value)
      // },
      // onChangePredicate: (value: any) => {
      //   // allow position numbers including 0
      //   return value >= 0
      // },
      gridProps: {
        xs: 12,
        sm: 6,
      },
    } as unknown as FormField
    const reservationFields = [
      {
        name: "clientId",
        fieldType: "text",
        required: true,
        visible: false,
      },
      {
        name: "language",
        fieldType: "text",
        required: true,
        visible: false,
      },
      {
        name: "expiresInDays",
        fieldType: "text",
        label: t("expiresInDays"),
        type: "number",
        visible: false,
        required: false,
        fullWidth: true,
        size: "small",
        InputProps: {
          endAdornment: t("days"),
        },
      },
      {
        name: "displayLanguage",
        fieldType: "text",
        label: t("communicationLanguage"),
        type: "text",
        required: false,
        fullWidth: true,
        size: "small",
        disabled: true,
        gridProps: {
          xs: 12,
          sm: 6,
        },
      },
      {
        name: "notes",
        fieldType: "text",
        label: t("notes"),
        fullWidth: true,
        required: false,
        InputProps: {
          multiline: true,
          rows: 2,
        },
      },
      {
        name: "employeeCode",
        fieldType: "text",
        label: t("employeeCode"),
        type: "text",
        required: true,
        fullWidth: true,
        size: "small",
      },
      {
        name: "thingTypeMrn",
        fieldType: "text",
        required: true,
        hidden: true,
        visible: false,
      },

      {
        name: "lockerId",
        fieldType: "text",
        required: true,
        hidden: true,
        visible: false,
      },
      {
        name: "doorId",
        fieldType: "text",
        required: true,
        hidden: true,
        visible: false,
      },
      {
        name: "tenantId",
        fieldType: "text",
        required: true,
        hidden: true,
        visible: false,
      },
      {
        name: "tenantUserId",
        fieldType: "text",
        required: true,
        hidden: true,
        visible: false,
      },
    ] as FormField[]
    let schema = reservationCreateSchema(t)
    // prepand amountToCollect field if payment is enabled
    if (locker?.id && locker.sstLocker?.payment) {
      reservationFields.unshift(amountToCollectField)
      // make amountToCollect required
      schema = reservationCreateSchema(t).keys({
        amountToCollect: Joi.number()
          .required()
          .messages({
            "any.required": t("pleaseProvideAmountToCollect"),
          }),
      })
    }
    return {
      recipentFields,
      reservationFields,
      schema,
    }
  }, [locker?.id, locker.sstLocker?.payment, selectedClient?.id, t])

  const onSelectedClientError = useCallback((errors: Record<string, any>) => {
    setSelectedClientErrors((prev: Record<string, any>) => {
      const nextErrors = errors
      // remove empty errors
      Object.keys(nextErrors).forEach((key) => {
        if (!nextErrors[key]) {
          delete nextErrors[key]
        }
      })
      return nextErrors
    })
  }, [])

  const [isResettingRecipientForm, setIsResettingRecipientForm] =
    React.useState(false)

  useEffect(() => {
    setIsResettingRecipientForm(true)
    // waiting 500 ms
    setTimeout(() => {
      setIsResettingRecipientForm(false)
      dispatch(thingsActions.clearClientSelectionSearchValues())
    }, 500)
  }, [dispatch, doorId])

  const stackRef = React.useRef<HTMLDivElement>(null)

  return (
    <Stack direction="column" spacing={2} p={2} ref={stackRef}>
      <Stack direction="row" spacing={1} alignItems="center">
        <CalendarMonthIcon />
        <Typography variant="body1" fontWeight="bold">
          {mblsReservationClient?.id
            ? `${t("newReservationFor")} ${mblsReservationClient?.firstName} ${
                mblsReservationClient?.lastName
              }`
            : t("newReservation")}
        </Typography>
      </Stack>

      <Stack direction="column" spacing={6}>
        <Paper sx={{ p: 1, bgcolor: "#FAFBFB" }} variant="outlined">
          <Stack direction="column" spacing={3}>
            {/* {!isEmpty(errors) && <FormErrorsAlert errors={errors} />} */}
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <PersonSearchIcon />
                <Typography variant="body1" fontWeight="bold">
                  {t("searchRecipient")}
                </Typography>
              </Stack>
              <Typography variant="body2">
                {t("searchRecipientDescription")}
              </Typography>
            </Stack>
            {!selectedClient && (
              <>
                {/* ?search form  */}
                {isResettingRecipientForm ? (
                  <Stack direction="column" spacing={1} sx={{ height: 380 }}>
                    <Skeleton variant="text" height="100px" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" height="100px" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" height="100px" />
                  </Stack>
                ) : (
                  <Sl2FormContainer joiSchema={Joi.object({})}>
                    <FormFields
                      fields={formFields.recipentFields}
                      onWatch={onValueChangeforSearch}
                      rowSpacing={1}
                    />
                  </Sl2FormContainer>
                )}
              </>
            )}
            <Stack direction="column" spacing={3}>
              {selectedClient && (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  className={`uf-selected-client`}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack direction="column" spacing={1}>
                    <Typography variant="body1">
                      {t("selectedClient")}
                    </Typography>
                    <Stack
                      direction="column"
                      spacing={1}
                      // alignItems="center"
                      // justifyContent="space-between"
                      sx={{
                        width: "100%",
                      }}
                    >
                      {selectedClient?.id && (
                        <OsReservationSelectedClient
                          onError={onSelectedClientError}
                          errors={selectedClientErrors}
                        />
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Paper>
      </Stack>
      <Stack direction="column" spacing={2}>
        <Typography variant="body1" fontWeight="bold">
          {t("reservationDetails")}
        </Typography>
        <Sl2FormContainer
          errors={formErrors}
          onError={(errors: any) => setFormErrors(errors)}
          onSuccess={onSubmit}
          joiSchema={formFields.schema}
          reValidateMode="onBlur"
        >
          <FormFields
            fields={formFields.reservationFields}
            // onWatch={onValueChangeforNonSearch}
            defaultValues={defaultValues}
            rowSpacing={1}
          />

          {formErrors[""]?.message && (
            <Stack direction="row" spacing={2} justifyContent="flex-end" pt={2}>
              <Typography variant="body2" color="error">
                {formErrors[""]?.message}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={2} justifyContent="flex-end" pt={2}>
            <Sl2Btn
              onClick={onClose}
              disabled={isProcessing}
              variant="outlined"
              fullWidth
            >
              {t("cancel")}
            </Sl2Btn>
            <Sl2Btn
              type="submit"
              disabled={
                isProcessing ||
                !!!selectedClient ||
                !isEmpty(selectedClientErrors)
              }
              variant="contained"
              fullWidth
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {isProcessing && <CircularProgress size={20} />}
                <Box>{t("create")}</Box>
              </Stack>
            </Sl2Btn>
          </Stack>
          {!selectedClient && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"flex-end"}
              sx={{
                py: 2,
              }}
            >
              <Typography variant="caption">
                {t("youMustSelectClientBeforeCreatingReservation")}
              </Typography>
            </Stack>
          )}
          {generalError && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={"flex-end"}
              sx={{
                py: 2,
                color: "error.main",
              }}
            >
              <Typography variant="caption">{generalError}</Typography>
            </Stack>
          )}
        </Sl2FormContainer>
      </Stack>
    </Stack>
  )
}
