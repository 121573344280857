import { ViewColumn, ViewStream } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
} from "@mui/material"
import React, { FC, useCallback, useMemo } from "react"
import { Locker } from "shared/locker"
import { LockerThingTypeMrn } from "shared/lockerData"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { getLockerDisplayValues } from "src/hooks/useLockerDisplayValues"
import { useAppSelector } from "../app/hooks"
import { selectMeIsReady, selectTenantId } from "../features/me/meSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { selectThingsByThingTypeMrn } from "../features/things/thingsSlice"
import { MblsReservationSearchBar } from "./MblsReservationSearchBar"
import OsLayout from "./OsLayout"
import OsLockerDashboard from "./OsLockerDashboard"
import OsLockerDoorDrawerManager from "./OsLockerDoorDrawerManager"

export const OsLockersPageEmbedded: FC = () => {
  const tenantId = useAppSelector(selectTenantId)

  const isReady = useAppSelector(selectMeIsReady)

  const { isLoading } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetLockers,
        "ap.tenantId": tenantId,
        "ap.refreshSstLockers": true,
        tenantId,
      },
    },
    {
      skip: !tenantId || !isReady,
    },
  )

  const _lockers = useAppSelector((s) =>
    selectThingsByThingTypeMrn(s, LockerThingTypeMrn),
  ) as Locker[]

  const lockers = useMemo(() => {
    return _lockers.sort((a, b) => {
      //   sort by name
      const aName = getLockerDisplayValues(a).name
      const bName = getLockerDisplayValues(b).name
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    })
  }, [_lockers])

  const onBannerClose = React.useCallback(() => {
    // postmessage to parent
    if (window.parent) {
      window.parent.postMessage({ event: "closeModal" }, "*")
    }
  }, [])

  const [direction, setDirection] = React.useState<"row" | "column">("row")

  const handleDirectionChange = useCallback(
    (newDirection: "row" | "column") => {
      setDirection(newDirection)
    },
    [],
  )

  return (
    <OsLayout selectedMenuItemLabelKey="lockers" hideMenu>
      <Box
        sx={{
          borderTop: "2px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        {isLoading || !isReady ? (
          <>
            <Stack direction="column" spacing={2}>
              <CircularProgress />
            </Stack>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100vw",
                alignItems: "center",
              }}
            >
              <MblsReservationSearchBar onClose={onBannerClose} />
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <IconButton
                  size="small"
                  color={direction === "row" ? "primary" : "default"}
                  onClick={() => handleDirectionChange("row")}
                >
                  <ViewStream />
                </IconButton>
                <IconButton
                  size="small"
                  color={direction === "column" ? "primary" : "default"}
                  onClick={() => handleDirectionChange("column")}
                >
                  <ViewColumn />
                </IconButton>
              </Stack>
            </Box>

            <Stack
              direction={direction}
              spacing={1}
              sx={{
                maxHeight: "calc(100vh - 64px)",
                overflow: "auto",
                alignItems: direction === "column" ? "center" : "flex-start",
              }}
              divider={
                <Divider
                  orientation={direction === "row" ? "vertical" : "horizontal"}
                  flexItem
                />
              }
            >
              {lockers.map((locker) => (
                <Box key={locker.id}>
                  <OsLockerDashboard lockerId={locker.id} />
                </Box>
              ))}
            </Stack>
          </>
        )}
      </Box>
      <OsLockerDoorDrawerManager />
    </OsLayout>
  )
}

export default OsLockersPageEmbedded
