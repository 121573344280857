// IoTContext.tsx
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

import { mqtt5 } from "aws-iot-device-sdk-v2"
import { useGetIoTCredsMutation } from "../features/me/meApi"

import { get, noop } from "lodash"
import { useSelector } from "react-redux"
import { SharedAccessPatternOsEnum } from "shared/sharedAccessPatternsData"
import { TenantUserThingTypeMrn } from "shared/tenantUserData"
import { v4 as uuid } from "uuid"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  selectMe,
  selectMeIsReady,
  selectTenantId,
  setOnlinePresence,
  updateMblsUser,
} from "../features/me/meSlice"
import { sstThingsActions } from "../features/partners/sstThingsSlice"
import { useGetThingsQuery } from "../features/things/thingsApi"
import { thingsActions } from "../features/things/thingsSlice"
import {
  OnMessage,
  connectMrt,
  iotClient,
  publish,
  publishAsync,
  subscribe,
  subscribeAsync,
  unsubscribe,
} from "./mrt"

type IoTContextType = {
  //   iotClient: mqtt5.Mqtt5Client | null
  isConnected: boolean
  isConnecting: boolean
  connect: (tenantId: string, cb?: () => void | Promise<void>) => Promise<void>
  disconnect: () => void
  subscribe: (config: mqtt5.SubscribePacket) => void
  subscribeAsync: (config: mqtt5.SubscribePacket) => Promise<void>
  unsubscribe: (config: mqtt5.UnsubscribePacket) => void
  lastMessageJson?: Record<string, any>
  publish: (config: mqtt5.PublishPacket) => void
}

const MrtContext = createContext<IoTContextType | null>(null)
let publishedPresence = false

export const MrtProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const [getIotCreds] = useGetIoTCredsMutation({})
  const [isConnected, setIsConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [connectionFailed, setConnectionFailed] = useState(false)
  const isReady = useAppSelector(selectMeIsReady)

  const { user } = useSelector(selectMe)
  const tenantId = useAppSelector(selectTenantId)
  // last messageJson
  const [lastMessageJson, setLastMessageJson] = useState({})
  const [fetchUsers, setFetchUsers] = useState(false)
  const { refetch: refetchUsers } = useGetThingsQuery(
    {
      params: {
        "ap.name": SharedAccessPatternOsEnum.GetUsersByTenant,
        "ap.tenantId": tenantId,
        tenantId,
      },
    },
    {
      skip: !fetchUsers,
    },
  )
  const onMessage: OnMessage = useCallback(
    (topic, messageJson) => {
      // topc include actionReq, console.log("onMessage", topic, messageJson)

      // console.log("onMessage", topic, messageJson)
      switch (messageJson?.type) {
        case "ThingCreated":
        case "ThingUpdated":
          dispatch(thingsActions.upsertOneDeep(messageJson.data))
          if (messageJson.data?.id === user?.id) {
            dispatch(updateMblsUser(messageJson.data))
          }
          if (messageJson.data?.thingTypeMrn === TenantUserThingTypeMrn) {
            if (fetchUsers) {
              refetchUsers()
            } else {
              setFetchUsers(true)
            }
          }
          break
        case "ThingDeleted":
          dispatch(thingsActions.removeOne(messageJson?.data?.id))
          break
        case "UserOnline":
          if (messageJson?.data?.userId === user?.id) {
            dispatch(setOnlinePresence(true))
          }
          break
        case "SstReservationUpdate":
          console.log("SstReservationUpdate", messageJson)
          if (messageJson.data.reservation?.door?.locker_id) {
            dispatch(
              sstThingsActions.upsertOneDeep({
                _id: messageJson.data.reservation?._id,
                sstType: "SstReservation",
                ...messageJson.data.reservation,
              }),
            )
          }
          break
        default:
          // Handle other cases or do nothing
          break
      }
      setLastMessageJson(messageJson)
    },
    [dispatch, fetchUsers, user?.id, refetchUsers],
  )

  const connectAsync = useCallback(
    async (tenantId: string, retryCount = 1) => {
      try {
        const resp = await getIotCreds({ params: { tenantId } })
        const error = get(resp, "error")
        if (error as any) {
          console.log("Error getting iot creds", error)
          // @ts-ignore
          throw new Error("Error getting iot creds :" + error?.message)
        }

        const iotCreds = get(resp, "data.data")
        if (!iotCreds) {
          throw new Error("No iot creds")
        }
        const { clientId } = await connectMrt(iotCreds, onMessage)

        setIsConnected(true)

        dispatch(setOnlinePresence(true))

        console.log("Connected to MRT")
        return clientId
      } catch (error) {
        setConnectionFailed(true)
        // await connectAsync(tenantId, retryCount + 1)
      }
    },
    [dispatch, getIotCreds, onMessage],
  )

  const connect = useCallback(
    (tenantId: string, cb = noop) => {
      if (connectionFailed) {
        console.log("Connection failed. Please reload the page to try again.")
        return
      }
      if (isConnecting) {
        console.log("Already connecting to MRT")
        return
      }

      if (isConnected) {
        console.log("Already connected to MRT")
        cb()
        return
      }
      setIsConnecting(true)
      connectAsync(tenantId)
        .then((clientId) => {
          if (publishedPresence || !user?.id) return
          publishedPresence = true
          return publishAsync({
            topicName: `mrt/us/${user?.id}/presence`,
            payload: JSON.stringify({
              mrtVerison: "1.0",
              id: uuid(),
              source: "Wfe.UserPresence",
              type: "UserOnline",
              timestamp: new Date().toISOString(),
              data: {
                userId: user?.id,
                userEmail: user?.email,
                userExternalId: user?.externalId,
                tenantId,
                clientId,
                userAgent: navigator.userAgent,
                // metadata info
                window: {
                  location: window.location.href,
                  screen: {
                    width: window.screen.width,
                    height: window.screen.height,
                  },
                  viewport: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                  },
                },
              },
            }),
            qos: mqtt5.QoS.AtLeastOnce,
          })
        })
        .then(cb)
        .catch((e) => {
          console.error("Error connecting to MRT", e)
        })
        .finally(() => {
          setIsConnecting(false)
        })
    },
    [connectAsync, isConnected, isConnecting, user?.id],
  )

  const disconnect = useCallback(() => {
    iotClient?.stop()
  }, [])

  const value = useMemo(() => {
    return {
      // iotClient,
      isConnected,
      isConnecting,
      subscribe,
      subscribeAsync,
      unsubscribe,
      connect,
      disconnect,
      lastMessageJson,
      publish,
    } as IoTContextType
  }, [isConnected, isConnecting, connect, disconnect, lastMessageJson])

  return <MrtContext.Provider value={value}>{children}</MrtContext.Provider>
}

export const useIoT = () => {
  const context = useContext(MrtContext)
  if (context === null) {
    throw new Error("useIoT must be used within a IoTProvider")
  }
  return context
}
