import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import Joi from "joi"
import { FC, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "src/app/hooks"
import { thingsActions } from "src/features/things/thingsSlice"
import useArcgisFindAddressCandidates from "src/hooks/useArcgisFindAddressCandidates"
import useArcgisSuggestions from "src/hooks/useArcgisSuggestions"
import { createClientObject } from "src/mblsDelivery/createClientObject"
import { postMessageRequest } from "src/postMessageRequest"
import FormFields, { FormField } from "./FormFields"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

type OsCreateMblsClientDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}
const formJoiSchema = Joi.object({
  lastName: Joi.string().required().min(1).max(1000).messages({
    "string.empty": "Last name is required",
  }),
  firstName: Joi.string().required().min(1).max(1000).messages({
    "string.empty": "First name is required",
  }),
  addressLabel: Joi.string().required().min(1).max(1000).messages({
    "string.empty": "Address label is required",
  }),
  appartNumber: Joi.string().optional().min(1).max(1000),
  addressNotes: Joi.string().optional().min(1).max(5000).allow(""),
  phoneLabel: Joi.string().required().min(1).max(1000),
  // phone number regex
  phoneNumber: Joi.string()
    .required()
    // .min(10)
    // .max(14)
    // .pattern(new RegExp(/^[+]*[(]{1,1}[0-9]{1,3}[)]{1,1}[-\s\./0-9]*$/))
    // enforce phone number to be in the format (123) 123-1234
    .pattern(
      new RegExp(
        /^[(]{1,1}[0-9]{3,3}[)]{1,1}[ ]{1,1}[0-9]{3,3}[-]{1,1}[0-9]{4,4}$/,
      ),
    )
    .message("Phone number must be valid format : (123) 123-1234"),
  notes: Joi.string().optional().min(1).max(5000).allow(""),
  addressArcgis: Joi.string().required().min(1).max(1000).messages({
    "string.empty": "Address is required",
  }),
  language: Joi.string().required().min(1).max(1000).valid("fr", "en"),
  email: Joi.string()
    .optional()
    .min(1)
    .max(1000)
    .email({ tlds: { allow: false } })
    .allow("")
    .messages({
      "string.email": "Email must be valid format",
    }),
}).or("phoneNumber", "email")

export const OsCreateMblsClientDialog: FC<OsCreateMblsClientDialogProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation()

  const [error, setError] = useState(false)

  const arc = useArcgisSuggestions()
  const arcCandi = useArcgisFindAddressCandidates()

  const [localAddress, setLocalAddress] = useState<string>()

  const onAddressChange = useCallback(
    (e: any) => {
      setLocalAddress(e?.target?.value)
      arc.refresh({
        text: e?.target?.value || "",
        maxSuggestions: 5,
      })
    },
    [arc],
  )

  const [defaultValues, setDefaultValues] = useState({
    phoneLabel: "Mobile",
    addressLabel: "Home",
    addressArcgis: "",
  })

  const onArcgisSuggestionClick = useCallback(
    (s: { text: string; magicKey: string }) => {
      // setLocalAddress("")
      setDefaultValues((prev) => ({ ...prev, addressArcgis: s.text }))
      arcCandi.findCandidates(s.text, s.magicKey)
    },
    [arcCandi],
  )

  const { fields0, fields1 } = useMemo(() => {
    const fields0: FormField[] = [
      {
        name: "lastName",
        label: t("lastName"),
        fieldType: "text",
        required: true,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: "firstName",
        label: t("firstName"),
        fieldType: "text",
        required: true,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: "addressLabel",
        label: t("type"),
        fieldType: "select",
        required: true,
        size: "small",
        fullWidth: true,
        options: [
          { id: "Home", label: t("home") },
          { id: "Work", label: t("work") },
          { id: "Other", label: t("other") },
        ],
        gridProps: { xs: 12, sm: 2 },
      },
      {
        name: "addressArcgis",
        label: t("address"),
        fieldType: "autocomplete",
        required: true,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 8 },
        autocompleteProps: {
          loading: arc.isLoading,
          name: "addressArcgis",
          options: localAddress
            ? arc.suggestions.map((s) => ({
                id: s.text,
                label: s.text,
              }))
            : [],
          autocompleteProps: {
            size: "small",
            onInputChange(event, value, reason) {
              setLocalAddress(value)
              onArcgisSuggestionClick({
                text: value,
                // find the magic key
                magicKey:
                  arc.suggestions.find((s) => s.text === value)?.magicKey || "",
              })
            },
            noOptionsText: t("noAddressFound"),
            loadingText: t("loading"),
          },
          textFieldProps: {
            onChange: onAddressChange,
            value: localAddress,
          },
        },
      },
      {
        name: "appartNumber",
        label: t("appt"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 2 },
      },
    ]
    const fields1: FormField[] = [
      {
        name: "addressNotes",
        label: t("addressNotes"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        InputProps: { multiline: true, rows: 2 },
        gridProps: { xs: 12, sm: 12 },
      },

      {
        name: "phoneLabel",
        label: t("type"),
        fieldType: "select",
        required: true,
        size: "small",
        fullWidth: true,
        options: [
          { id: "Mobile", label: t("mobile") },
          { id: "Home", label: t("home") },
          { id: "Work", label: t("work") },
          { id: "Other", label: t("other") },
        ],
        gridProps: { xs: 12, sm: 2 },
      },
      {
        name: "phoneNumber",
        label: t("phoneNumber"),
        fieldType: "text",
        required: true,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 10 },
        inputMask: "(999) 999-9999",
      },
      {
        name: "notes",
        label: t("notes"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 12 },
        InputProps: { multiline: true, rows: 5 },
      },
      {
        name: "email",
        label: t("email"),
        fieldType: "text",
        required: false,
        size: "small",
        fullWidth: true,
        gridProps: { xs: 12, sm: 6 },
      },
      {
        name: "language",
        label: t("language"),
        fieldType: "select",
        required: true,
        size: "small",
        fullWidth: true,
        options: [
          { id: "fr", label: t("french") },
          { id: "en", label: t("english") },
        ],
        gridProps: { xs: 12, sm: 2 },
      },
    ]
    return { fields0, fields1 }
  }, [
    arc.isLoading,
    arc.suggestions,
    localAddress,
    onAddressChange,
    onArcgisSuggestionClick,
    t,
  ])

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <DialogTitle id="alert-dialog-title">{t("createClient")}</DialogTitle>
        {isLoading && <CircularProgress size={20} />}
      </Stack>
      <Divider />
      <DialogContent>
        <Stack direction="column" spacing={1}>
          <Sl2FormContainer
            joiSchema={formJoiSchema}
            onSuccess={async (values) => {
              setError(false)
              // for phoneNumber, remove () and - and space
              const phoneNumber = values.phoneNumber.replace(/[\s()-]/g, "")
              // console.log("arcCandi", arcCandi)
              const mblsClient = createClientObject({
                ...values,
                phoneNumber,
                mblsAddress: arcCandi.mblsAddresses[0],
              } as any)
              setIsLoading(true)
              try {
                const res = await postMessageRequest(window.parent, {
                  type: "createClient",
                  payload: mblsClient,
                })
                console.log("res", res)
                setDefaultValues({
                  phoneLabel: "Mobile",
                  addressLabel: "Home",
                  addressArcgis: "",
                })
                if (res?.payload?.client?.id) {
                  dispatch(
                    thingsActions.updateClientSelectionDefaultValues({
                      clientId: res?.payload?.client?.id,
                    }),
                  )
                }
                dispatch(
                  thingsActions.setClientSelectionSearchValues({
                    firstName: values.firstName,
                    lastName: values.lastName,
                  }),
                )

                // wailt 500 ms to let redux dispatch finish
                setTimeout(() => {
                  onSuccess()
                }, 500)
              } catch (e: any) {
                console.error(e)
                setError(true)
              } finally {
                setIsLoading(false)
                setLocalAddress("")
              }
            }}
          >
            <FormFields fields={fields0} defaultValues={defaultValues} />
            <Box pt={2} />
            <FormFields fields={fields1} defaultValues={defaultValues} />
            <DialogActions>
              <Sl2Btn onClick={onClose} color="primary" disabled={isLoading}>
                {t("cancel")}
              </Sl2Btn>
              <Sl2Btn
                type="submit"
                color="primary"
                variant="contained"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={16} /> : t("create")}
              </Sl2Btn>
            </DialogActions>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              {error && (
                <Typography variant="caption" color="error">
                  {t("unableToCreateClient")}
                </Typography>
              )}
            </Stack>
          </Sl2FormContainer>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
