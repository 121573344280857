import { Close } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"

import { get, isEmpty, noop, pick } from "lodash"
import moment from "moment"
import React, { useCallback, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form-mui"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { Client } from "shared/client"
import { ClientThingTypeMrn } from "shared/clientData"
import { sharedClientCreateSchema } from "shared/sharedClientValidators"
import { useGetMblsConnectorClientsQuery } from "src/features/mblsConnector/mblsConenctorApi"
import { MblsConnectorClient } from "src/features/mblsConnector/mblsConnector"
import {
  useCreateThingsMutation,
  useDeleteThingsMutation,
  useUpdateThingsMutation,
} from "src/features/things/thingsApi"
import { useCurrentTenant } from "src/hooks/useCurrentTenant"
import { useKeyPress } from "../hooks/useKeyPress"
import FormFields, { FormField } from "./FormFields"
import { MblsConnectorClientSelector } from "./MblsConnectorClientSelector"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"
import TraceTooltip from "./TraceTooltip"

interface ClientDrawerProps {
  open: boolean
  onClose: () => void
  onCreated?: () => void
  onUpdated?: () => void
  onError?: (error: any) => void
  // mode: "create" | "edit"
  client?: Client | null
}

const ClientDrawer: React.FC<ClientDrawerProps> = ({
  open,
  onClose,
  onCreated = noop,
  onUpdated = noop,
  onError = noop,
  client,
}) => {
  const { t } = useTranslation()

  const [isProcessing, setIsProcessing] = React.useState(false)

  const [generalError, setGeneralError] = React.useState("")

  const formFields = useMemo(() => {
    // firstName, lastName, email, phone, status, mblsClientId, notes
    return [
      {
        name: "lastName",
        fieldType: "text",
        label: t("lastName"),
        type: "text",
        required: false,
        fullWidth: true,
        size: "small",
      },
      {
        name: "firstName",
        fieldType: "text",
        label: t("firstName"),
        required: false,
        fullWidth: true,
        size: "small",
      },
      {
        name: "email",
        fieldType: "text",
        label: t("email"),
        type: "email",
        required: false,
        fullWidth: true,
        size: "small",
      },
      {
        name: "phone",
        fieldType: "text",
        label: t("phone"),
        type: "tel",
        required: false,
        fullWidth: true,
        size: "small",
        inputMask: "(999) 999-9999",
      },
      {
        name: "notes",
        fieldType: "text",
        label: t("notes"),
        fullWidth: true,
        required: false,
        InputProps: {
          multiline: true,
          rows: 4,
        },
      },
      {
        name: "language",
        label: t("language"),
        fieldType: "select",
        required: false,
        size: "small",
        fullWidth: true,
        options: [
          { id: "fr", label: t("french") },
          { id: "en", label: t("english") },
        ],
        gridProps: { xs: 12 },
      },
      {
        name: "thingTypeMrn",
        fieldType: "text",
        required: true,
        hidden: true,
        visible: false,
      },
    ] as FormField[]
  }, [t])

  const { tenant } = useCurrentTenant()
  const [defaultClient, setDefaultClient] = React.useState({
    thingTypeMrn: ClientThingTypeMrn,
    tenantId: tenant?.id,
    ...client,
  })

  const [hasAutofilled, setHasAutofilled] = React.useState(false)

  const [searchValues, setSearchValues] = React.useState({})

  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()

  const isCreateMode = useMemo(() => isEmpty(client), [client])

  const { reset } = useForm()

  useEffect(() => {
    if (isCreateMode) {
      reset({
        thingTypeMrn: ClientThingTypeMrn,
        tenantId: tenant?.id,
      })
      setDefaultClient({
        thingTypeMrn: ClientThingTypeMrn,
        tenantId: tenant?.id,
      })
    }
    if (!isEmpty(client)) {
      reset(client)
      setDefaultClient(client)
    }
  }, [client, isCreateMode, reset, tenant?.id])

  const { data, isLoading: isMblsConnectorClientsLoading } =
    useGetMblsConnectorClientsQuery(
      { params: searchValues },
      {
        skip: isEmpty(searchValues) || !isCreateMode || !tenant?.mblsProviderId,
      },
    )
  const clients = get(data, "data", [])

  const onSelect = useCallback(
    (client: MblsConnectorClient) => {
      if (isCreateMode) return

      setDefaultClient((prev) => ({
        ...prev,
        firstName: client.firstName,
        lastName: client.lastName,
        email: client.email,
        phone: client.phones?.[0]?.number,
        thingTypeMrn: ClientThingTypeMrn,
        tenantId: tenant?.id,
      }))
      setHasAutofilled(true)
    },
    [isCreateMode, tenant?.id],
  )

  const navigate = useNavigate()

  const location = useLocation()

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setGeneralError("")
        setIsProcessing(true)
        if (!tenant?.id) throw new Error("tenantId is not defined")
        const client = {
          ...data,
          // remove () and - and space from phone number
          phone: data?.phone?.replace(/[\s()-]/g, ""),
          thingTypeMrn: ClientThingTypeMrn,
          tenantId: tenant?.id,
        }
        if (isCreateMode) {
          const res = await createThings({
            body: { things: [client] },
            params: { tenantId: tenant?.id },
          }).unwrap()

          onCreated()

          const newClientId = get(res, "things[0].id")
          if (newClientId) {
            navigate(`/os/clients/${newClientId}${location.search}`)
          }
        } else {
          await updateThings({
            body: {
              things: [
                pick(client, [
                  "id",
                  "thingTypeMrn",
                  "tenantId",
                  "firstName",
                  "lastName",
                  "email",
                  "phone",
                  "notes",
                  "language",
                ]),
              ],
            },
            params: { tenantId: tenant?.id },
          }).unwrap()
        }
        onUpdated()
      } catch (e: any) {
        console.error(e)
        onError(e)
      } finally {
        setIsProcessing(false)
      }
    },
    [
      createThings,
      isCreateMode,
      location.search,
      navigate,
      onCreated,
      onError,
      onUpdated,
      tenant?.id,
      updateThings,
    ],
  )

  // const onValueChange = useCallback(
  //   throttle((value: any) => {
  //     if (hasAutofilled) {
  //       setHasAutofilled(false)
  //       return
  //     }
  //     setSearchValues(value)
  //   }, 1000),
  //   [hasAutofilled],
  // )
  useKeyPress("Escape", onClose)

  const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false)

  const [deleteThings] = useDeleteThingsMutation()

  const [isProcessingDelete, setIsProcessingDelete] = React.useState(false)

  const [deletionError, setDeletionError] = React.useState("")

  const onDelete = useCallback(async () => {
    setIsProcessingDelete(true)

    setDeletionError("")

    try {
      if (!tenant?.id) throw new Error("tenantId is not defined")
      await deleteThings({
        body: {
          things: [
            {
              id: client?.id,
              thingTypeMrn: ClientThingTypeMrn,
              tenantId: tenant?.id,
            },
          ],
        },
        params: { tenantId: tenant?.id },
      }).unwrap()
      setIsProcessingDelete(false)
      onClose()
    } catch (e: any) {
      console.error(e)
      setDeletionError(e.message)
      setIsProcessingDelete(false)
    }
  }, [client?.id, deleteThings, onClose, tenant?.id])

  return (
    <Box>
      <Drawer anchor="right" open={open} onClose={onClose} variant="persistent">
        <Toolbar />
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          p={2}
        >
          {tenant?.mblsProviderId &&
            false &&
            isCreateMode &&
            clients &&
            !isEmpty(searchValues) && (
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  p: 1,
                  height: "90vh",
                  overflowY: "auto",
                  width: 400,
                }}
              >
                <MblsConnectorClientSelector
                  clients={clients as unknown as MblsConnectorClient[]}
                  isLoading={isMblsConnectorClientsLoading}
                  onSelect={onSelect}
                />
              </Box>
            )}
          <Stack sx={{ width: 400, p: 2 }} spacing={3} direction="column">
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent={"space-between"}
              //   p={2}
              pb={4}
            >
              <Typography variant="body1" fontWeight="bold">
                {isCreateMode
                  ? t("newClient")
                  : // @ts-ignore
                  (client && client?.firstName) ?? client?.lastName
                  ? `${client["firstName"]} ${client["lastName"]}`
                  : // last 5 digits of the id
                    `${t("client")} ${client?.id?.slice(-10)}`}
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>
            <Sl2FormContainer
              defaultValues={defaultClient}
              onError={(errors: any) => {
                if (errors[""]) {
                  setGeneralError(errors[""]?.message)
                }
              }}
              onSuccess={onSubmit}
              joiSchema={sharedClientCreateSchema as any}
            >
              <FormFields
                fields={formFields}
                // onWatch={isCreateMode ? onValueChange : noop}
                defaultValues={defaultClient}
              />
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                pt={2}
              >
                <Sl2Btn
                  onClick={onClose}
                  disabled={isProcessing}
                  variant="outlined"
                  fullWidth
                >
                  {t("cancel")}
                </Sl2Btn>
                <Sl2Btn
                  type="submit"
                  disabled={isProcessing}
                  variant="contained"
                  fullWidth
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    {isProcessing && <CircularProgress size={16} />}
                    {t(isCreateMode ? "create" : "modify")}
                  </Stack>
                </Sl2Btn>
              </Stack>
              <Typography variant="caption" color="error">
                {generalError}
              </Typography>
            </Sl2FormContainer>
            <Box pt={10} />
            {!isCreateMode && (
              <>
                <Divider />
                <Sl2Btn
                  variant="outlined"
                  fullWidth
                  color="error"
                  onClick={() => setIsConfirmingDelete(true)}
                >
                  {t("delete")}
                </Sl2Btn>
              </>
            )}

            {client && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                mt={10}
              >
                <TraceTooltip
                  text={client.traceId || "n/a"}
                  tooltipTitle={
                    <Typography variant="caption" sx={{ mt: 2 }}>
                      {client.id}
                    </Typography>
                  }
                />
                {client.updatedAt && (
                  <Typography variant="caption" sx={{ mt: 2 }}>
                    {`${t("updated")} ${moment(client.updatedAt).format(
                      "YYYY-MM-DD HH:mm",
                    )}`}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
        {!isCreateMode && (
          <Dialog open={isConfirmingDelete}>
            <DialogTitle>{t("deleteClient")}</DialogTitle>
            <DialogContent sx={{ minHeight: 100 }}>
              <Stack direction="column" spacing={1}>
                <DialogContentText>
                  {t("deleteClientConfirmMessage")}
                </DialogContentText>
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Sl2Btn
                onClick={() => {
                  setIsConfirmingDelete(false)
                }}
                variant="outlined"
              >
                {t("cancel")}
              </Sl2Btn>
              <Sl2Btn
                onClick={onDelete}
                variant="contained"
                color="error"
                disabled={isProcessingDelete}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  {isProcessingDelete && <CircularProgress size={16} />}
                  <Stack direction="row" spacing={1} alignItems="center">
                    {t("delete")}
                  </Stack>
                </Stack>
              </Sl2Btn>
            </DialogActions>
            {deletionError && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                p={1}
                justifyContent={"flex-end"}
              >
                <Typography variant="caption" color="error">
                  {t("errorWhileDeletingClient")}
                </Typography>
              </Stack>
            )}
          </Dialog>
        )}
      </Drawer>
    </Box>
  )
}

export default ClientDrawer
