import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material"
import { t } from "i18next"
import Joi from "joi"
import { noop, pick } from "lodash-es"
import moment from "moment"
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { SharedAccessPatternSaEnum } from "shared/sharedAccessPatternsData"
import { Tenant } from "shared/tenant"
import { TenantThingTypeMrn } from "shared/tenantData"
import { User } from "shared/user"
import { useAppDispatch } from "src/app/hooks"
import { mblsconnectorApi } from "src/features/mblsConnector/mblsConenctorApi"
import {
  useCreateThingsMutation,
  useGetThingsQuery,
  useUpdateThingsMutation,
} from "../features/things/thingsApi"
import FormFields, { FieldsGeneratorFn, FormField } from "./FormFields"
import { Sl2Btn } from "./Sl2Btn"
import { Sl2FormContainer } from "./Sl2FormContainer"

const defaultTenant: Partial<Tenant> = {
  thingTypeMrn: TenantThingTypeMrn,
}

const createSchema = Joi.object({
  // thingTypeMrn: Joi.string().required(),
  name: Joi.string().required(),
  description: Joi.string().optional(),
  website: Joi.string().optional(),
  contactName: Joi.string().optional(),
  contactEmail: Joi.string()
    .email({
      tlds: { allow: false },
    })
    .optional(),
  address: Joi.string().optional(),
  city: Joi.string().optional(),
  state: Joi.string().optional(),
  country: Joi.string().optional(),
  postalCode: Joi.string().optional(),
  phone: Joi.string().optional(),
  ownerId: Joi.string().required().messages({
    "any.required": "Owner is required",
  }),
  mblsProviderId: Joi.number().integer().optional().messages({
    "number.base": "Mobilus Delivery Provider ID must be a number",
  }),
  thingTypeMrn: Joi.string().required(),
})
const updateSchema = Joi.object({
  name: Joi.string().required(),
  description: Joi.string().optional(),
  website: Joi.string().optional(),
  contactName: Joi.string().optional(),
  contactEmail: Joi.string()
    .email({
      tlds: { allow: false },
    })
    .optional(),
  address: Joi.string().optional(),
  city: Joi.string().optional(),
  state: Joi.string().optional(),
  country: Joi.string().optional(),
  postalCode: Joi.string().optional(),
  phone: Joi.string().optional(),
  thingTypeMrn: Joi.string().required(),
  ownerId: Joi.string().optional(),
  mblsProviderId: Joi.number().integer().optional(),
  id: Joi.string().required(),
})

const fieldsGeneralFn: FieldsGeneratorFn = (action) => [
  {
    name: "name",
    fieldType: "text",
    label: "Name",
    required: true,
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12 },
  },
  {
    name: "description",
    fieldType: "text",
    label: "Description",
    required: false,
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12 },
    InputProps: {
      multiline: true,
      rows: 2,
    },
  },
  // {
  //   name: "status",
  //   fieldType: "select",
  //   label: "Status",
  //   required: true,
  //   size: "small",
  //   fullWidth: true,
  //   disabled: true,
  //   gridProps: { xs: 12, sm: 6 },
  //   visible: action !== "create",
  // },

  {
    name: "website",
    fieldType: "text",
    label: "Website",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    name: "contactName",
    fieldType: "text",
    label: "Contact Name",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    name: "contactEmail",
    fieldType: "text",
    label: "Contact Email",
    size: "small",
    fullWidth: true,
    InputProps: {
      type: "email",
    },
    gridProps: { xs: 12, sm: 6 },
  },
  // hiddenfield thingTypeMrn
  {
    name: "thingTypeMrn",
    fieldType: "text",
    label: "Thing Type MRN",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
    visible: false,
  },
]
const fieldsAddressFn: FieldsGeneratorFn = (action) => [
  {
    name: "address",
    fieldType: "text",
    label: "Address",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    name: "city",
    fieldType: "text",
    label: "City",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    name: "state",
    fieldType: "text",
    label: "State",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    name: "country",
    fieldType: "text",
    label: "Country",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    name: "postalCode",
    fieldType: "text",
    label: "Postal Code",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
  {
    name: "phone",
    fieldType: "text",
    label: "Phone",
    size: "small",
    fullWidth: true,
    gridProps: { xs: 12, sm: 6 },
  },
]

export const SaOrgForm: FC<{
  tenant?: Tenant
  isCreating?: boolean
  onFinish?: (isSuccess: boolean) => void
  onCancel?: () => void
}> = ({
  tenant = defaultTenant,
  isCreating = false,
  onFinish = noop,
  onCancel = noop,
}) => {
  const patch = useMemo(() => {
    return tenant ? pick(tenant, Object.keys(defaultTenant)) : defaultTenant
  }, [tenant])

  const [snackbarData, setSnackbarData] = React.useState({
    severity: "success" as "success" | "error" | "info" | "warning",
    message: "",
  })

  const { data } = useGetThingsQuery({
    params: {
      "ap.name": SharedAccessPatternSaEnum.SaGetUsers,
      "ap.isSuperAdmin": true,
    },
  })

  const users = useMemo(() => {
    return (data?.things || []) as User[]
  }, [data])

  const [updateThings, { isLoading: isUpdatingThings }] =
    useUpdateThingsMutation()

  // create
  const [createThings, { isLoading: isCreatingThings }] =
    useCreateThingsMutation()

  const onSubmit = useCallback(
    async (data: any) => {
      const mutateFn = isCreating ? createThings : updateThings
      let thing = { ...defaultTenant, ...data }
      if (!isCreating) {
        thing = pick(thing, [
          "name",
          "description",
          "website",
          "contactName",
          "contactEmail",
          "address",
          "city",
          "state",
          "country",
          "postalCode",
          "phone",
          "id",
          "thingTypeMrn",
        ])
      }

      const res = await mutateFn({ body: { things: [thing] } }).unwrap()

      setSnackbarData({
        severity: "success",
        message: isCreating
          ? "Organization created successfully"
          : "Organization updated successfully",
      })
      onFinish(true)
    },
    [createThings, isCreating, onFinish, updateThings],
  )
  const dispatch = useAppDispatch()

  const [isFetchingMblsProvider, setIsFetchingMblsProvider] = useState(false)

  const fetchMblsProvider = useCallback(
    async (providerId: string) => {
      setIsFetchingMblsProvider(true)
      setSnackbarData({
        severity: "info",
        message: "Fetching Mobilus Delivery Provider...",
      })
      try {
        const res = await dispatch(
          mblsconnectorApi.endpoints.getMblsProvider.initiate({
            params: { providerId },
          }),
        )
        console.log("fetchMblsProvider", res)
        const provider = res?.data?.data[0]
        if (!provider) {
          setSnackbarData({
            severity: "error",
            message: "Mobilus Delivery Provider not found",
          })
          return
        }
        const {
          street_name,
          street_number,
          city,
          state,
          postcode,
          country,
          name,
          code,
          email,
          phone_number,
        } = provider

        setAutofillValues({
          name,
          description: code,
          address: `${street_number} ${street_name}`,
          city,
          state,
          postalCode: postcode,
          country,
          contactEmail: email,
          phone: phone_number,
        })

        setSnackbarData({
          severity: "success",
          message: "Mobilus Delivery Provider fetched successfully",
        })
      } catch (error) {
        console.error(error)
        setSnackbarData({
          severity: "error",
          message: "Error fetching Mobilus Delivery Provider",
        })
      } finally {
        setIsFetchingMblsProvider(false)
      }
    },
    [dispatch, setSnackbarData, setIsFetchingMblsProvider],
  )

  const [autofillValues, setAutofillValues] = useState<Record<
    string,
    any
  > | null>(null)

  const { fieldsGeneral, fieldsAddress, fieldsOwner, fieldsIntegration } =
    useMemo(() => {
      const action = isCreating ? "create" : "update"
      const userOptions = users.map((u) => ({
        id: u.id,
        label: (
          <Typography variant="body2">{`${u.firstName} ${u.lastName} (${u.email})`}</Typography>
        ),
      }))

      const noEmptyOptions = [{ id: "", label: t("noUsersToAdd") }]
      return {
        fieldsGeneral: fieldsGeneralFn(action),
        fieldsAddress: fieldsAddressFn(action),
        fieldsOwner: [
          {
            name: "ownerId",
            fieldType: "select",
            options: userOptions.length ? userOptions : noEmptyOptions,
            label: t("owner"),
            placeholder: t("selectUser"),
            required: true,
            size: "small",
            fullWidth: true,
            disabled: action === "update",
            valueKey: "id",
            gridProps: { xs: 12, sm: 6 },
          } as FormField,
        ],
        fieldsIntegration: [
          {
            name: "mblsProviderId",
            fieldType: "text",
            label: "Mobilus Delivery Provider ID",
            size: "small",
            fullWidth: true,
            required: false,
            disabled: action === "update",
            gridProps: { xs: 12, sm: 6 },
            EndComponent: ({ field, muiField }) => {
              const providerId = muiField.value
              if (!providerId) {
                return null
              }
              return (
                <Stack direction="row" spacing={1}>
                  <Sl2Btn
                    variant="contained"
                    size="small"
                    onClick={() => fetchMblsProvider(providerId)}
                    disabled={isFetchingMblsProvider}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      {isFetchingMblsProvider && <CircularProgress size={16} />}
                      <>Auto-fill</>
                    </Stack>
                  </Sl2Btn>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    onClick={() =>
                      setAutofillValues({
                        name: "",
                        description: "",
                        address: "",
                        city: "",
                        state: "",
                        postalCode: "",
                        country: "",
                        contactEmail: "",
                        phone: "",
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    {t("clear")}
                  </Typography>
                </Stack>
              )
            },
          } as FormField,
        ],
      }
    }, [fetchMblsProvider, isCreating, isFetchingMblsProvider, users])
  const [formDefaultValues, setFormDefaultValues] = useState<
    Record<string, any>
  >({
    thingTypeMrn: TenantThingTypeMrn,
  })
  useEffect(() => {
    setFormDefaultValues(
      isCreating
        ? {
            ...patch,
            thingTypeMrn: TenantThingTypeMrn,
            ...autofillValues,
          }
        : pick(tenant, [
            "name",
            "description",
            "website",
            "contactName",
            "contactEmail",
            "address",
            "city",
            "state",
            "country",
            "postalCode",
            "phone",
            "id",
            "thingTypeMrn",
            "ownerId",
            "mblsProviderId",
          ]),
    )
  }, [patch, autofillValues, isCreating, tenant])
  return (
    <Card variant="outlined">
      <CardContent>
        {isCreating && (
          <Typography variant="h6" noWrap gutterBottom>
            Create organization:
          </Typography>
        )}
        {isCreating && <Divider sx={{ mb: 5 }} />}
        <Sl2FormContainer
          defaultValues={formDefaultValues}
          joiSchema={isCreating ? createSchema : updateSchema}
          onSuccess={onSubmit}
        >
          <FormFields fields={fieldsGeneral} />
          <Box sx={{ mt: 4, mb: 4 }}>
            <Divider />
            <Typography variant="subtitle1" noWrap>
              🏠 Address
            </Typography>
          </Box>
          <FormFields
            fields={fieldsAddress}
            defaultValues={formDefaultValues}
          />
          <Box sx={{ mt: 4, mb: 4 }}>
            <Divider />
            <Typography variant="subtitle1" noWrap>
              {t("owner")}
            </Typography>
          </Box>
          <FormFields fields={fieldsOwner} />
          <Box sx={{ mt: 4, mb: 4 }}>
            <Divider />
            <Typography variant="subtitle1" noWrap>
              {t("integrations")}
            </Typography>
          </Box>
          <FormFields
            fields={fieldsIntegration}
            defaultValues={formDefaultValues}
          />
          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                textTransform: "none",
                mt: 4,
              }}
              disabled={isUpdatingThings || isCreatingThings}
            >
              {isCreating ? "Create organization" : "Update organization"}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={onCancel}
              sx={{
                textTransform: "none",
                mt: 4,
              }}
              disabled={isUpdatingThings || isCreatingThings}
            >
              Cancel
            </Button>
          </Stack>
        </Sl2FormContainer>
        <Snackbar
          open={snackbarData.message !== ""}
          onClose={() =>
            setSnackbarData({
              severity: "success",
              message: "",
            })
          }
          autoHideDuration={5000}
          message={snackbarData?.message}
        >
          <Alert severity={snackbarData.severity} variant="filled">
            {snackbarData?.message}
          </Alert>
        </Snackbar>
      </CardContent>
      {!isCreating && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          // justifyContent="space-between"
          sx={{ p: 1 }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography variant="caption" color="textSecondary">
            {`Created ${moment(tenant.createdAt).format(
              "DD/MM/YYYY HH:mm",
            )} - ${moment(tenant.createdAt).fromNow()}`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {tenant.id}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            <Link to={`/sa/event-log?traceId=${tenant.traceId}`}>
              {tenant.traceId}
            </Link>
          </Typography>
        </Stack>
      )}
    </Card>
  )
}

export default SaOrgForm
