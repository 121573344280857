import { useEffect } from "react"
const DisableNumInputScroll = () => {
  const handleWheel = (event: any) => {
    const type = event?.target?.type
    if (type === "number") {
      event.preventDefault()
    }
  }
  useEffect(() => {
    document.addEventListener("wheel", handleWheel, { passive: false })

    return () => {
      document.removeEventListener("wheel", handleWheel)
    }
  }, [])

  return null
}

export default DisableNumInputScroll
